<template>
  <van-pagination v-model="page" :items-per-page="itemsPerPage" :page-count="lastPageNo" :show-page-size="5"
                  :total-items="totalItems"
                  class="custom-pagination" force-ellipses v-bind="$attrs"
                  @change="emit('change')">
    <template #prev-text>
      <div class="me-3 flex items-center gap-4">
        <nuxt-link :to="getFormattedPageUrl(1)" @click.stop="onPageUpdate(1)">
          <van-icon class="flip-icon" name="arrow-double-left"/>
        </nuxt-link>
        <nuxt-link :to="getFormattedPageUrl(page - 1)">
          <van-icon class="flip-icon" name="arrow-left"/>
        </nuxt-link>
      </div>
    </template>
    <template #next-text>
      <div class="ms-3 flex items-center gap-4">
        <nuxt-link :to="getFormattedPageUrl(page + 1)">
          <van-icon class="flip-icon" name="arrow"/>
        </nuxt-link>
        <nuxt-link :to="getFormattedPageUrl(lastPageNo)" @click.stop="onPageUpdate(lastPageNo)">
          <van-icon class="flip-icon" name="arrow-double-right"/>
        </nuxt-link>
      </div>
    </template>
    <template #page="{ text }">
      <nuxt-link v-if="typeof text === 'number'" :to="getFormattedPageUrl(text)">
        {{ text }}
      </nuxt-link>
      <span v-else>{{ text }}</span>
    </template>
  </van-pagination>
</template>

<script setup>
const props = defineProps({
  itemsPerPage: {
    type: Number,
    default: 30
  },
  totalItems: {
    type: Number,
    default: 0
  }
})

const MAX_PAGE_NUMBER = 333

const emit = defineEmits(['change'])

const page = defineModel()
const route = useRoute()

const lastPageNo = computed(() => {
  const pages = props.totalItems / props.itemsPerPage
  return pages > MAX_PAGE_NUMBER ? Math.floor(pages) : Math.ceil(pages)
})

const getFormattedPageUrl = (pageNo = 1) => {

  // Hide extra pages
  if (pageNo > lastPageNo.value) return

  const {$routerUrl} = useNuxtApp()

  const {page, ...otherParams} = route.query

  const queryParams = Object.entries(otherParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')

  return $routerUrl(`${route.path}?page=${pageNo || 1}&${queryParams}`)
}

const router = useRouter()
const onPageUpdate = (pageNo = 1) => {
  emit('change')
  page.value = pageNo
  router.push(getFormattedPageUrl(pageNo))
}
</script>

<style>
.custom-pagination .van-pagination__item {
  background: transparent;
}

.custom-pagination .van-pagination__item--active {
  border-radius: 100%;
  background: #997adb;
}

.custom-pagination .van-hairline--surround:after {
  border-width: 0;
}
</style>